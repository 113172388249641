import "./Copyright.css";

const Copyright = ({ copyright }) => (
  <div className="copyright">
    <p className="copyright__text">
      {copyright} {new Date().getFullYear()}
    </p>
  </div>
);

export default Copyright;
