import "./News.css";
import Content from "../Content";
import NewsCard from "../NewsCard";
import Pagination from "../Pagination";
import { useState, useEffect } from "react";
import { useFetchData } from "../../hooks/useFetchData";
// import data from "../../data/news.json";

// v1.1.0
import axios from "axios";
import Moment from "moment";
var XMLParser = require("react-xml-parser");

const News = ({ lang }) => {
  const { data } = useFetchData("news.json");
  const [news, setNews] = useState({ state: false });

  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(6);

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const pageCount = Math.ceil(news.xml && news.xml.length / itemsPerPage);

  // Get feed
  useEffect(() => {
    axios
      .get("https://soliq.uz/press-services/rss", {
        "Content-Type": "application/xml; charset=utf-8",
      })
      .then(function (response) {
        var xml = new XMLParser().parseFromString(response.data); // Assume xmlText contains the  XMexampleL

        if (!xml) return setNews({ state: false, xml: null });

        setNews({
          state: true,
          xml: xml.children[0].children.slice(4, 100),
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const handlePageChange = ({ selected }) => setCurrentPage(selected);

  const displayDocuments = (documents) => {
    if (!documents) return null;

    return documents.slice(startIndex, endIndex).map((item, i) => {
      if (!document.title) return null;

      console.log(documents);

      return (
        <NewsCard
          //   lang={}
          key={i}
          title={item.children[0].value}
          // text={}
          imgURL={item.children[3].value}
          pageURL={item.children[1].value}
          date={Moment(item.children[4].value).format("DD/MM/YYYY")}
        />
      );
    });
  };

  if (!data) return null;

  return (
    <div className="news">
      <Content>
        <h2 className="news__title">{data[lang].title}</h2>

        <div className="news__cards">{displayDocuments(news.xml)}</div>

        <Pagination pageCount={pageCount} onPageChange={handlePageChange} />
      </Content>
    </div>
  );
};

export default News;
