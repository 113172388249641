import "./DocumentsDownload.css";
import DocumentsDownloadCard from "../DocumentsDownloadCard";
import moment from "moment";
import { useEffect, useState } from "react";

const DocumentsDownload = ({ data, startIndex, endIndex, lang }) => {
   const [docs, setDocs] = useState([]);

   useEffect(() => {
      const rawDocs = data?.files?.map((item) => {
         const getYear = item.date.substring(6);
         const getDay = item.date.substring(0, 2);
         const getMonth = item.date.substring(3, 5);

         return {
            ...item,
            date: {
               year: getYear,
               day: getDay,
               month: getMonth,
            },
         };
      });

      if (rawDocs.length > 0) {
         setDocs(
            rawDocs.sort(
               (a, b) =>
                  b.date.year - a.date.year ||
                  b.date.month - a.date.month ||
                  b.date.day - a.date.day
            )
         );
      }

      return () => false;
   }, [data]);

   const displayDocuments = (documents) => {
      if (!documents) return null;

      return documents.slice(startIndex, endIndex).map((document, i) => {
         if (!document.title) return null;

         return (
            <DocumentsDownloadCard
               key={document.title.replace(" ", "-") + "-" + i}
               date={document.date}
               title={document.title}
               format={document.format}
               size={document.size}
               downloadURL={document.downloadURL}
               lang={lang}
            />
         );
      });
   };

   if (data)
      return <div className="documents-download">{displayDocuments(docs)}</div>;

   return null;
};

export default DocumentsDownload;
